import React, { useState } from "react";
import { motion } from "framer-motion";

import CloseIcon from "@svg/close.svg";
import PhoneIcon from "@svg/phone.svg";

import { ModuleWrapper } from "../moduleWrapper";
import PortalModal from "@components/ui/portalModal";
import { PortableTextBlock } from "@components/sanity/portableTextBlock";
import { SanityImage } from "@components/sanity/sanityImage";

const ManagerPreview = ({ name, position, phone, image }) => {
  return (
    <div className="flex items-start gap-x-3 md:items-center">
      <div className="w-20 flex-shrink-0">
        <div className="aspect-w-1 aspect-h-1 relative rounded-2xl overflow-hidden">
          <SanityImage
            image={image}
            className="absolute inset-0 object-cover"
          />
        </div>
      </div>
      <div className="flex-grow">
        <h4 className="text-2xl font-medium">{name}</h4>
        <h5 className="text-base font-medium">{position}</h5>
        <div className="md:hidden mt-2">
          <a href={`tel:${phone}`} className="btn">
            <PhoneIcon className="w-3 self-center mr-2 fill-current" />
            {phone}
          </a>
        </div>
      </div>
      <div className="hidden md:block">
        <a href={`tel:${phone}`} className="btn">
          <PhoneIcon className="w-3 self-center mr-2 fill-current" />
          {phone}
        </a>
      </div>
    </div>
  );
};

const CustomerSupport = ({ config, managersInfo, managers }) => {
  const [managersOpen, setManagersOpen] = useState(false);
  return (
    <ModuleWrapper {...config}>
      <div className="bg-sand-900 px-gutter py-20 lg:py-48">
        <h2 className="blockH0 text-center">Need more help ?</h2>
        <div className="grid gap-y-10 mt-10 md:grid-cols-3 md:max-w-6xl md:mx-auto lg:mt-16">
          {/* Managers */}
          <div className="text-center">
            <h4 className="text-lg font-medium">Territory Managers</h4>
            <button
              className="text-xl font-medium underline lg:text-2xl"
              onClick={() => setManagersOpen(true)}
            >
              View Managers
            </button>
          </div>
          {/* Managers */}
          <div className="text-center">
            <h4 className="text-lg font-medium">Account Setup</h4>
            <a
              className="text-xl font-medium underline lg:text-2xl"
              href="https://www.credential.net.au/Application/entryform.html?sUrlId=rQUhdXuVPCQkHnsa1_yoWeK1BoxAmtGtvA1et1bqHvj6mYjU8DP8LcdY4GHS7pR5&sShowAll=N&sNew=N&sLoad=N&sStart=Y"
              target="_blank"
              rel="noreferrer"
            >
              Get Started
            </a>
          </div>
          {/* Managers */}
          <div className="text-center">
            <h4 className="text-lg font-medium">Customer Service</h4>
            <a
              className="text-xl font-medium underline lg:text-2xl"
              href="mailto:orders@premiumliquor.co.nz"
            >
              Get in touch
            </a>
          </div>
        </div>
      </div>
      <PortalModal isShowing={managersOpen}>
        <motion.div className="bg-sand-900 w-full max-w-2xl relative p-4 self-start md:mx-auto md:p-6 md:rounded-xl md:myr-10">
          <button
            onClick={() => setManagersOpen(false)}
            className="absolute top-4 right-4"
          >
            <CloseIcon className="w-6" />
          </button>
          <PortableTextBlock text={managersInfo} />
          <div className="mt-10 grid gap-y-2 md:gap-y-10">
            {managers.map((item) => (
              <ManagerPreview {...item} />
            ))}
          </div>
        </motion.div>
      </PortalModal>
    </ModuleWrapper>
  );
};

export default CustomerSupport;
